import { SvgIcon } from "@mui/material";
import React from "react";

const Twitter = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 10 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.55556 0C2.41466 0 3.11111 0.696446 3.11111 1.55556V3.88889H7.77778C8.63689 3.88889 9.33333 4.58533 9.33333 5.44444C9.33333 6.30355 8.63689 7 7.77778 7H3.11111V8.55556C3.11111 9.84422 4.15578 10.8889 5.44444 10.8889H7.77778C8.63689 10.8889 9.33333 11.5853 9.33333 12.4444C9.33333 13.3036 8.63689 14 7.77778 14H5.44444C2.43756 14 0 11.5624 0 8.55556V1.55556C0 0.696446 0.696446 0 1.55556 0Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Twitter;
